import _ from "lodash";

const isFunction = (element) => {
  if(element.type === 'expression') {
    if(element.value.includes('x') || element.value.startsWith('y=')) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

const isAnonymousFunction = (value) => {
  return (value.startsWith('y=') || !value.includes('='))
}

const parseFunction = (element) => {
  const elementCopy = _.cloneDeep(element)

  elementCopy.type = 'function'
  elementCopy.parsedValue = elementCopy.value

  if(isAnonymousFunction(elementCopy.value)) {
    elementCopy.parsedValue = elementCopy.parsedValue.replace(/^y=/, '')
    elementCopy.name = _.uniqueId('anonymous_function_')
    elementCopy.parsedValue = `${elementCopy.name}(x)= ` + elementCopy.parsedValue
  } else {
    elementCopy.name = elementCopy.parsedValue.split('(x)=')[0].trim()
  }

  return elementCopy
}


export const parseElements = (elements) => {
  return elements.map(element => {
    if(isFunction(element)) {
      return parseFunction(element)
    } else {
      element.parsedValue = element.value
      return element
    }
  })
}

export const isSlider = (element) => {
  return element.value.match(/^\w+=-?\d+$/);
}
