import { Navigate } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Navigate replace to="/graficzny" />
    </div>
  );
}

export default Home;
