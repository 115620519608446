import React from 'react';
import Element from './Element';
import Stack from '@mui/material/Stack';

const ElementList = ({elements, updateElement, removeElement, sliders, updateSlider}) => {
  return (
    <Stack spacing={2}>
      {elements.map(element => {
        let slider = sliders.find(slider => slider.elementId === element.id);
        return <Element key={element.id} element={element} updateElement={updateElement} removeElement={removeElement} slider={slider} updateSlider={updateSlider} />
      })}
    </Stack>
  )
}


export default ElementList
