import _ from "lodash";
import React, { useState, useRef } from 'react'
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { parse } from 'mathjs'
import { MathComponent } from "mathjax-react";

const MathViewNew = ({value, onChange}) => {
  const [open, setOpen] = useState(value ? false : true);
  const ref = useRef(null);
  const id = _.uniqueId("mathview-");

  const handleChange = (ev) => {
    const trimmedValue = ev.target.value.replace(/\s+/g, '')
    onChange(trimmedValue)
  }

  const handleClickAway = () => {
    setOpen(false);
  }

  const handleClick = () => {
    setOpen(true);
    // set focus with delay
    setTimeout(() => {
      ref.current.focus();
    }, 100);
  }
  let texValue = ""
  try {
    texValue = parse(value).toTex({parenthesis: "keep"})
  } catch (e) {
    console.log(e)
  }

  return (
    <FormControl fullWidth onBlur={handleClickAway}>
      <TextField fullWidth style={{ display: open ? '' : 'none' }} inputRef={ref} id={id} label="Wyrażenie" variant="outlined" onChange={handleChange} value={value}/>
      <div style={{ display: !open ? '' : 'none' }} onClick={handleClick}><MathComponent tex={texValue} /></div>
    </FormControl>
  )
}

export default MathViewNew
