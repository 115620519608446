import _ from "lodash";
import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import MathViewNew from 'atoms/MathViewNew';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

const Element = ({element, updateElement, removeElement, slider, updateSlider}) => {
  const [hover, setHover] = useState(false);

  const handleUpdateElement = (value) => {
    let elementCopy = _.cloneDeep(element);
    elementCopy.value = value;
    updateElement(element.id, elementCopy)
  }

  const handleRemoveElement = () => {
    removeElement(element.id)
  }

  const handleOnMouseEnter = () => {
    setHover(true);
  }

  const handleOnMouseLeave = () => {
    setHover(false);
  }

  const isNumeric = (val) => {
    return /^-?\d+$/.test(val);
  }

  const handleSliderChange = (event) => {
    let sliderCopy = _.cloneDeep(slider);
    const targetValue = event.target.value;
    if (isNumeric(targetValue)) {
      sliderCopy.value = Number(targetValue);
      updateSlider(element, sliderCopy);
      const elementCopy = _.cloneDeep(element);
      elementCopy.value = `${elementCopy.value.split("=")[0]}=${sliderCopy.value}`;
      updateElement(elementCopy.id, elementCopy);
    }
  }

  const handleSliderMinChange = (event) => {
    let sliderCopy = _.cloneDeep(slider);
    const targetValue = event.target.value;
    sliderCopy.minValue = targetValue;
    updateSlider(element, sliderCopy);
  }

  const handleSliderMaxChange = (event) => {
    let sliderCopy = _.cloneDeep(slider);
    const targetValue = event.target.value;
    sliderCopy.maxValue = targetValue;
    updateSlider(element, sliderCopy);
  }

  const parseStringAsNumber = (string) => {
    if (isNumeric(string)) {
      return Number(string);
    } else {
      return 0;
    }
  }


  return (
    <div>
      <Paper elevation={hover ? 3 : 1} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={0}
        >
          <MathViewNew value={element.value} onChange={handleUpdateElement} />
          <IconButton aria-label="delete" size="large" onClick={handleRemoveElement}>
            <ClearIcon />
          </IconButton>
        </Stack>
        {slider && <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <TextField variant="outlined" value={slider.minValue} onChange={handleSliderMinChange} />
          <Slider aria-label="Default" valueLabelDisplay="auto" value={slider.value} min={parseStringAsNumber(slider.minValue)} max={parseStringAsNumber(slider.maxValue)} onChange={handleSliderChange} />
          <TextField variant="outlined" value={slider.maxValue} onChange={handleSliderMaxChange} />
        </Stack>}
      </Paper>
    </div>
  )
}


export default Element
