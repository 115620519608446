import { range, parser } from 'mathjs'

class ElementsPlotter {
    constructor(elements) {
        this.elements = elements;
        this.xValues = range(-50, 50, 0.1).toArray()
        this.parser = parser()
    }

    evaluateElements() {
        try {
            this.elements.forEach(element => {
                this.parser.evaluate(element.parsedValue)
            })
        } catch (e) {
            console.log(e)
        }

    }

    plotElements() {
        return this.elements
            .filter(element => (element.type === 'function'))
            .map(element => {
                try {
                    const yValues = this.xValues.map(function (x) {
                        return this.parser.evaluate(`${element.name}(${x})`)
                    }.bind(this))

                    return (
                        {
                            x: this.xValues,
                            y: yValues,
                            type: 'scatter',
                            mode: 'lines',
                            line: {
                                color: element.color,
                                width: 3
                            },
                            name: element.value
                        }
                    )
                } catch (e) {
                    console.log(e)
                    return null
                }
            })
            .filter(element => element !== null)
    }
}

export default ElementsPlotter;
