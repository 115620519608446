import _ from "lodash";
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import IosShareIcon from '@mui/icons-material/IosShare';
import ElementList from "molecules/ElementList";
import Plot from 'react-plotly.js';
import { parseElements, isSlider } from "helpers/elementHelpers";
import ElementsPlotter from "helpers/ElementsPlotter";
import Stack from '@mui/material/Stack';
import { useParams } from "react-router-dom";
import { BootstrapDialog, BootstrapDialogTitle } from "molecules/BootstrapDialog";

const Calculator = () => {
  let initialValue = []
  let initialSliders = []

  let params = useParams();
  const baseUrl = process.env.REACT_APP_BACKEND_API_URL

  const [elements, setElements] = useState(initialValue);
  const [sliders, setSliders] = useState(initialSliders);
  const [shareDialog, setShareDialog] = useState(false);
  const [shareNotification, setShareNotification] = useState(false);
  const [shareUrl, setShareUrl] = useState("");

  const handleCloseShareNotification = () => {
    setShareNotification(false);
  }

  const handleShareDialogClose = () => {
    setShareDialog(false);
  };

  const handleCopyShareUrl = () => {
    navigator.clipboard.writeText(shareUrl);
    setShareNotification(true);
  }

  if (params.uuid && elements?.length === 0) {
    fetch(`${baseUrl}/api/calculator/graphic_algebras/${params.uuid}.json`)
      .then(response => response.json())
      .then(data => {
        setElements(data.state.elements)
        setSliders(data.state.sliders)
      })
  }

  const handleShare = () => {
    const url = `${baseUrl}/api/calculator/graphic_algebras.json`
    const data = {
      state: {
        elements: elements,
        sliders: sliders
      }
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        try {
          setShareUrl(`${window.location.origin}/graficzny/${data.uuid}`)
          setShareDialog(true);
        } catch (error) {
          console.log(error)
        }
      })
  }

  const parsedElements = parseElements(elements);
  const plotter = new ElementsPlotter(parsedElements);
  plotter.evaluateElements();

  const updateElement = (id, newElement) => {
    let elementCopy = _.cloneDeep(newElement);
    setElements(elements.map(e => e.id === id ? elementCopy : e));
    if(isSlider(elementCopy)) {
      initializeSlider(elementCopy);
      updateSliderForElement(elementCopy);
    } else {
      removeSlider(elementCopy);
    }
  }

  const removeElement = (id) => {
    console.log(id);
    setElements(elements.filter(e => e.id !== id));
  }

  const addElement = () => {
    setElements([...elements, {
      id: _.uniqueId("element-"),
      type: "expression",
      value: "",
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`
    }]);
  }

  const initializeSlider = (element) => {
    if (!sliders.find(s => s.elementId === element.id)) {
      const sliderValue = Number(element.value.split("=")[1])
      let slider = {
        id: _.uniqueId("slider-"),
        elementId: element.id,
        value: sliderValue,
        defaultValue: sliderValue,
        minValue: -10,
        maxValue: 10
      }
      setSliders([...sliders, slider]);
    }
  }

  const updateSlider = (element, newSlider) => {
    if (newSlider) {
      const sliderCopy = _.cloneDeep(newSlider);
      setSliders(sliders.map(s => s.id === sliderCopy.id ? sliderCopy : s));
    }
  }

  const updateSliderForElement = (element) => {
    const slider = sliders.find(s => s.elementId === element.id);
    if (slider) {
      const sliderCopy = _.cloneDeep(slider)
      sliderCopy.value = Number(element.value.split("=")[1]);
      setSliders(sliders.map(s => s.id === sliderCopy.id ? sliderCopy : s));
    }
  }

  const removeSlider = (element) => {
    setSliders(sliders.filter(s => s.elementId !== element.id));
  }

  return (
    <>
      <Snackbar
        open={shareNotification}
        autoHideDuration={6000}
        onClose={handleCloseShareNotification}
        message="Skopiowane"
      />
      <BootstrapDialog
        onClose={handleShareDialogClose}
        aria-labelledby="customized-dialog-title"
        open={shareDialog}
        fullWidth={true}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleShareDialogClose}>
          Udostępnij
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            id="share-irl"
            label="Link"
            fullWidth
            variant="standard"
            value={shareUrl}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyShareUrl}>Skopiuj</Button>
        </DialogActions>
      </BootstrapDialog>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} xl={3}>
          <Stack
            direction="row"
            spacing={1}
          >
            <IconButton aria-label="add" size="large" onClick={addElement}>
              <AddIcon />
            </IconButton>
            <IconButton aria-label="add" size="large" onClick={handleShare}>
              <IosShareIcon />
            </IconButton>
          </Stack>
          <ElementList elements={elements} updateElement={updateElement} removeElement={removeElement} sliders={sliders} updateSlider={updateSlider}  />
        </Grid>
        <Grid item xs={12} md={8} xl={9}>
          <div>
            <Plot
              data={plotter.plotElements()}
              layout={{
                autosize: true,
                xaxis: {
                  range: [-10, 10]
                },
                yaxis: {
                  scaleanchor: 'x',
                  range: [-10, 10]
                }
              }}
              useResizeHandler={true}
              style={{width: "100%", height: "100vh"}}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Calculator;
